<template>
  <div v-if="isBigger" class="d-block col-md-12">
    <h3 v-show="confirmed > 0" class="text-success mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.orders.confirmed', {nr: confirmed})}}
    </h3>
    <h3 v-show="partial > 0" class="text-warning mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.orders.partial', {nr: partial})}}
    </h3>
    <h3 v-show="unconfirmed > 0" class="text-danger mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.orders.unconfirmed', {nr: unconfirmed})}}
    </h3>
  </div>
  <div v-else class="d-block col-md-12">
    <h5 v-show="confirmed > 0" class="text-success mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.orders.confirmed', {nr: confirmed})}}
    </h5>
    <h5 v-show="partial > 0" class="text-warning mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.orders.partial', {nr: partial})}}
    </h5>
    <h5 v-show="unconfirmed > 0" class="text-danger mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.orders.unconfirmed', {nr: unconfirmed})}}
    </h5>
  </div>
</template>

<script>
export default {
  name: "OrderStatusCard",
  props: {
    orderStatus: Object,
    isBigger: {
      type: Boolean,
      default: false,
    },
    onlyWithQty: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    confirmed: function () {
      return this.onlyWithQty ? (this.orderStatus.confirmed.length - this.orderStatus.no_qty) : this.orderStatus.confirmed.length;
    },
    unconfirmed: function () {
      let total = this.onlyWithQty ? (this.orderStatus.total - this.orderStatus.no_qty) : this.orderStatus.total;
      return total - this.confirmed;
    },
    partial: function () {
      return 0;
    }
  },
}
</script>

<style scoped>

</style>