export default class BatchOrderTracking
{
    batch;
    totalOrders = [];
    confirmedOrders = [];
    items = {};

    constructor(batch) {
        this.batch = batch;
        this.totalOrders = batch.getConfirmedOrders();
    }

    addOrder(order)
    {
        if (typeof this.items[order.getId()] !== 'undefined') {
            return;
        }

        this.items[order.getId()] = {
            total: order.getItemsForBatch(this.batch.getId()).length,
            confirmed: [],
            no_qty: order.getItemsForBatch(this.batch.getId()).length - order.getItemsWithQtyForBatch(this.batch.getId()).length,
        }

        order.getItemsForBatch(this.batch.getId()).forEach(
            item =>
                (item.isReady() || item.getQty() === 0) &&
                this.items[order.getId()].confirmed.push(item.getId())
        );

        if (this.items[order.getId()].total - this.items[order.getId()].confirmed.length === 0) {
            this.confirmOrder(order.getId());
        }
    }

    confirmOrder(orderId)
    {
        if (this.isOrderConfirmed(orderId)) {
            // this.unconfirmOrder(orderId);
            return;
        }
        this.confirmedOrders.push(orderId);
    }

    unconfirmOrder(orderId)
    {
        this.confirmedOrders = this.confirmedOrders.filter(order => order !== orderId);
        if (this.areOrderItemsConfirmed(orderId)) {
            this.items[orderId].confirmed = [];
        }
    }

    confirmItem(orderId, itemId)
    {
        if (this.isItemConfirmed(orderId, itemId)) {
            this.unconfirmItem(orderId, itemId);
            return;
        }
        this.items[orderId].confirmed.push(itemId);
    }

    unconfirmItem(orderId, itemId)
    {
        this.items[orderId].confirmed = this.items[orderId].confirmed.filter(item => item !== itemId);
        this.unconfirmOrder(orderId);
    }

    isItemConfirmed(orderId, itemId)
    {
        return this.items[orderId].confirmed.includes(itemId);
    }

    isOrderConfirmed(orderId)
    {
        return this.confirmedOrders.includes(orderId);
    }

    areOrderItemsConfirmed(orderId)
    {
        return this.items[orderId].confirmed.length == this.items[orderId].total;
    }

    areOrdersConfirmed()
    {
        return this.totalOrders.length == this.confirmedOrders.length;
    }

    getOrderItemStatuses(orderId)
    {
        return typeof this.items[orderId] != 'undefined' ? this.items[orderId] : null;
    }

    getFirstUnconfirmedOrder()
    {
        let diff = this.totalOrders.filter(x => !this.confirmedOrders.includes(x));
        return diff.shift();
    }
}