<template>
  <div class="card p-2 text-center bg-verde">
    <div class="row text-center justify-content-center">
      <div class="col-sm-5 col-md-3 d-block align-self-center"><h2 class="mb-0 font-weight-semibold">
        {{ $t('frontoffice.farmers.pack.order.order') }}</h2></div>
      <div class="col-sm-5 col-md-5 d-block align-self-center">
        <SelectInput class="mb-0 verde" @input="getOrder" :options="getOrderOptions" :reference="batch"
                     :selected="order ? order.getId() : ''"/>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between my-3">
    <Button class="" @click="getOrder(null, getPrevOrder)" :disabled="0 == getPrevOrder"
            name="frontoffice.farmers.pack.buttons.prev" type="prev"/>
    <Button class="" @click="getOrder(null, getNextOrder)" :disabled="0 == getNextOrder"
            name="frontoffice.farmers.pack.buttons.next" type="next"/>
  </div>

  <div v-if="order">
    <div class="card p-2 bg-verde">
      <OrderSummaryCard :with-order-nr="true" :order="order" :batch="batch.getId()"/>
    </div>
    <div class="mt-1">
      <h3 class="text-center font-italic">{{ $t('frontoffice.farmers.pack.order.description_hint') }}</h3>
    </div>
    <OrderItemCard @click="orderTrack.confirmItem(order.getId(), item.getId())"
                   v-for="item in order.getItemsWithQtyForBatchOrderByFragile(batch.getId())" :item="item"
                   :key="item.getId()" :is-complete="orderTrack.isItemConfirmed(order.getId(), item.getId())"
                   :order-is-ready="order.isReadyByBatch(batch.getId())"/>
    <Card v-if="orderTrack.getOrderItemStatuses(order.getId())" :with-header="false" :with-footer="false">
      <template v-slot:default>
        <h3 class="text-center">{{$t('frontoffice.farmers.pack.buttons.invoice')}}</h3>
        <span class="d-block mt-1 mb-1 text-center" v-if="!orderTrack.isOrderConfirmed(order.getId())">{{ $t('frontoffice.farmers.pack.info.order_complete') }}</span>
        <upload-file-drag-drop-multiple
            v-model="orderInvoices"
            name="invoices"
            :multiple="true"
            :with-border="false"
            :disabled="false"
            :placeholder="'frontoffice.farmers.pack.buttons.upload_invoice'"
            :files-list-label="'frontoffice.farmers.pack.buttons.uploaded_invoices'"
            :empty-message="'frontoffice.farmers.pack.buttons.uploaded_empty'"
            :empty-message-class="'text-danger'"
            @filesChanged="uploadDocuments(order)"
            :show-empty-alert="true"
        > <!-- orderTrack.isOrderConfirmed(order.getId()) -->
        </upload-file-drag-drop-multiple>
      </template>
    </Card>

    <OrderStatusCard :is-bigger="true" :order-status="orderTrack.getOrderItemStatuses(order.getId())"/>

    <div class="my-4">
      <Button v-if="!orderTrack.isOrderConfirmed(order.getId())" @click="packOrder(order)"
              :disabled="!orderTrack.areOrderItemsConfirmed(order.getId())"
              name="frontoffice.farmers.pack.buttons.order" type="confirm-mb-0" class="mx-auto w-100 w-sm-auto"/>
      <span class="d-block mb-0 text-success text-center" v-else>
        {{ $t('frontoffice.farmers.pack.buttons.order_complete') }} <i class="icon-check2"></i>
      </span>
    </div>

    <div class="d-flex justify-content-between my-4">
      <Button class="" @click="getOrder(null, getPrevOrder)" :disabled="0 == getPrevOrder"
              name="frontoffice.farmers.pack.buttons.prev" type="prev"/>
      <Button class="" @click="getOrder(null, getNextOrder)" :disabled="0 == getNextOrder"
              name="frontoffice.farmers.pack.buttons.next" type="next"/>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import SelectInput from "@/components/elements/SelectInput";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import Order from "@/entities/Order";
import OrderSummaryCard from "@/components/order/frontoffice/OrderSummaryCard";
import OrderItemCard from "@/components/order/frontoffice/OrderItemCard";
import Button from "@/components/elements/Button";
import OrderStatusCard from "@/components/order/frontoffice/OrderStatusCard";
import BatchOrdersTracking from "@/entities/BatchOrderTracking";
import {ScrollMixin} from "@/mixins/ViewMixin";
import UploadFileDragDropMultiple from "@/components/elements/UploadFileDragDropMultiple";

export default {
  name: "FarmerPackList",
  components: {
    UploadFileDragDropMultiple,
    OrderStatusCard, Button, OrderItemCard, OrderSummaryCard, SelectInput, Card},
  mixins: [LoadingModalMixin, ScrollMixin],
  props: {
    batch: Object,
  },
  emits: ['showSuccessModal', 'showWarningModal'],
  data() {
    return {
      orderInvoices: [],
      order: null,
      orderTrack: new BatchOrdersTracking(this.batch),
      showSuccessModal: false,
      attemptToCollectBatch: true,
    }
  },
  mounted() {
    if (this.batch.getConfirmedOrders()[0]) {
      this.getOrder(this.batch, this.batch.getConfirmedOrders()[0], true);
    }
  },
  watch: {
    orderTrack: {
      immediate: true,
      deep: true,
      handler: function () {
        this.showSuccessModal = this.orderTrack.areOrdersConfirmed();
        if (this.showSuccessModal) {
          this.$emit("showSuccessModal");
        }
      }
    },
    isLoading: {
      immediate: true,
      handler: function (flag) {
        if (flag || !this.showSuccessModal || !this.attemptToCollectBatch) {
          return;
        }

        this.updateBatchToCollectionReady();
      }
    },
    orderInvoices(value) {
      console.log(value);
    }
  },
  computed: {
    getOrderOptions: function () {
      let options = [];
      for (let it in this.batch.getConfirmedOrders()) {
        options.push({
          val: this.batch.getConfirmedOrders()[it],
          txt: this.$i18n.t('frontoffice.farmers.pack.order.select_current', {
            current: (parseInt(it) + 1),
            total: this.batch.getConfirmedOrdersCollection().getSize()
          }),
        });
      }
      return options;
    },
    getNextOrder: function () {
      if (!this.order) {
        return 0;
      }
      for (let it = 0; it < this.getOrderOptions.length - 1; it++) {
        if (this.order.getId() == this.getOrderOptions[it].val) {
          return this.getOrderOptions[it + 1].val;
        }
      }
      return 0;
    },
    getPrevOrder: function () {
      if (!this.order) {
        return 0;
      }
      for (let it = 1; it < this.getOrderOptions.length; it++) {
        if (this.order.getId() == this.getOrderOptions[it].val) {
          return this.getOrderOptions[it - 1].val;
        }
      }
      return 0;
    },
  },
  methods: {
    updateBatchToCollectionReady: function () {
      let that = this;
      this.queueJob();
      this.axios.put(this.$store.state.config.getBatchesCollectionReadyUri(this.batch.getId())).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.attemptToCollectBatch = false;
        that.completeJob();
      });
    },
    getOrder(reference, orderId, checkAll = false) {

      if (orderId < 1) {
        return;
      }

      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getOrderUri(orderId)).then(
          response => {
            let content = response.data;
            that.order = new Order(content.data);
            that.orderTrack.addOrder(that.order);
            that.orderInvoices = that.order.getInvoice()
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
        if (!that.order.isReadyByBatch(that.batch.getId()) &&
            that.order.getItemsWithQtyForBatch(that.batch.getId()).length === 0) {
          //already pack an empty order
          that.packOrder(that.order);
        } else {
          let unconfirmedOrder = that.orderTrack.getFirstUnconfirmedOrder();
          if (checkAll && unconfirmedOrder && unconfirmedOrder !== 0 && unconfirmedOrder != orderId) {
            that.getOrder(reference, unconfirmedOrder, checkAll);
          }
        }
      });
    },

    uploadDocuments(order) {
      let that = this;
      that.queueJob();
      let formData = new FormData();
      //console.log(this.orderInvoices);
      this.orderInvoices.forEach((invoice) => {
        if (invoice instanceof File) {
          formData.append('new_invoice[]', invoice);
        }
        else {
          formData.append('old_invoice[]', JSON.stringify(invoice));
        }
      });
      this.axios.post(this.$store.state.config.getUploadOrderInvoiceUri(order.getId()), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
          (response) => {
            let content = response.data;
            that.order = new Order(content.data);
            that.orderTrack.addOrder(that.order);
            that.orderInvoices = that.order.getInvoice();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(false);
      });
    },

    packOrder(order) {
      if (!this.orderTrack.areOrderItemsConfirmed(order.getId())) {
        return;
      }
      this.orderTrack.confirmOrder(order.getId());

      let that = this;
      // that.queueJob();
      // let formData = new FormData();
      // //console.log(this.orderInvoices);
      // this.orderInvoices.forEach((invoice) => {
      //   if (invoice instanceof File) {
      //     formData.append('new_invoice[]', invoice);
      //   }
      //   else {
      //     formData.append('old_invoice[]', JSON.stringify(invoice));
      //   }
      // });
      // this.axios.post(this.$store.state.config.getUploadOrderInvoiceUri(order.getId()), formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // }).then(
      //     () => {
      //       that.scrollToTop();
      //     }
      // ).catch(
      //     error => {
      //       console.warn(error);
      //     }
      // ).finally(function () {
      //   that.completeJob(true);
      // });

      order.getItemsForBatch(this.batch.getId()).forEach(
          item => {
            that.queueJob();
            this.axios.put(this.$store.state.config.getReadyItemOrdersUri(order.getId(), item.getId())).then(
                () => {
                  that.scrollToTop();
                }
            ).catch(
                error => {
                  let statusCode = error.response ? error.response.status : null;
                  if (statusCode == 406) {
                    this.$emit("showWarningModal");
                  }
                  console.warn(error);
                }
            ).finally(function () {
              that.completeJob(true);
            });
          });
    },
    onLoadingComplete() {
      if (this.initialise) {
        this.getOrder(null, this.getNextOrder);
      }
    },
  },
}
</script>

<style scoped>

</style>
