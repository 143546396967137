<template>
<Card :card-body-style="isComplete ? 'complete-bg' : ''" :with-header="false" :with-footer="false" > <!-- :with-footer="!orderIsReady" -->
  <template v-slot:default>
    <h3 class="p-0 m-0 font-weight-semibold cursor-pointer d-flex align-items-center" @click="packItem">
      <span class="flex-grow-1">
        {{ item.getProduct().getDisplayName() }} - <span> {{ formatQty(item.getQty(), item.getProduct().getUnit()) }} </span>
        <span v-if="item.isFragile()"> - <span class="bg-warning">{{$t('general.fragile')}}</span></span>
      </span>
      <i class="ml-2" :class="{ 'icon-checkmark-circle2 text-success': isComplete, 'icon-radio-unchecked text-grey-300 opacity-50': !isComplete }" style="font-size: 1.5em" ></i>
    </h3>
  </template>
  <!--
  <template v-slot:footer>
    <Button v-if="!isButtonClicked" class="ml-auto w-100" @click="packItem" type="confirm" :name="$t('frontoffice.farmers.pack.buttons.order_item')" />
    <Button v-else class="ml-auto w-100" @click="packItem" type="cancel-discreet" :name="$t('frontoffice.farmers.pack.buttons.order_item_cancel')" />
  </template>
  -->
</Card>
</template>

<script>
import Card from "@/components/elements/Card";
import {NumbersMixin} from "@/mixins/GeneralMixin";
// import Button from "@/components/elements/Button";

export default {
  name: "OrderItemCard",
  components: {
    // Button,
    Card},
  emits: ['click'],
  mixins: [NumbersMixin],
  props: {
    item: Object,
    orderIsReady: {
      type: Boolean,
      default: false,
    },
    isComplete: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      isButtonClicked: this.isComplete
    }
  },
  methods: {
    packItem: function () {
      this.isButtonClicked = !this.isButtonClicked;
      this.$emit('click');
    }
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
